import * as React from 'react';
import { useSelector } from 'react-redux';
import { CardExtrasProvider } from './CardExtras.context';
import { getOrder, getFlight, getSegments, getTravellers, getAdditionalServices, getBrandIncludedBaggage } from '../Checkout/store/order/selectors';
import { getPassengerStateCompatible, getSelectedServicesState } from '../Checkout/store/selectedServices/selectors';
import { useOrderServices } from '../Checkout/hooks';
const CheckoutCardExtrasProvider = ({ children, userInfo, updateUser }) => {
    const order = useSelector(getOrder);
    const flight = useSelector(getFlight);
    const segments = useSelector(getSegments);
    const travellers = useSelector(getTravellers);
    const additionalServices = useSelector(getAdditionalServices);
    const passengers = useSelector(getPassengerStateCompatible);
    const selectedServices = useSelector(getSelectedServicesState);
    const brandIncludedBaggage = useSelector(getBrandIncludedBaggage);
    const { isLoading, onSaveServicesRequest, onAddServices, onRemoveServices } = useOrderServices();
    return (React.createElement(CardExtrasProvider, { value: {
            order,
            flight,
            segments,
            travellers,
            additionalServices,
            passengers,
            selectedServices,
            brandIncludedBaggage,
            userInfo,
            updateUser,
            isLoading,
            onSaveServicesRequest,
            onSaveServices: onAddServices,
            onAddServices,
            onRemoveServices
        } }, children));
};
export default CheckoutCardExtrasProvider;
