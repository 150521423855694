import { __awaiter } from "tslib";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { OrderAdditionalServiceGdsServiceServiceType, useAddTravellersDataMutation } from '@websky/graphql';
import { fillOrder } from '../../../Checkout/store/order/actions';
import { useOrderService } from '../hooks';
import { getChangedServicesToSave, getUpdatedTravellersPhones } from './utils';
export const useSmsNotifications = (services, orderId, onClose) => {
    const dispatch = useDispatch();
    const { onSaveHandler, onRemoveHandler } = useOrderService(services, OrderAdditionalServiceGdsServiceServiceType.Vip, true);
    const [addTravellersDataMutation, { loading: isAddTravellersDataLoading }] = useAddTravellersDataMutation();
    const onSubmit = useCallback((values, form) => __awaiter(void 0, void 0, void 0, function* () {
        onClose();
        const dirtyFields = form.getState().dirtyFields;
        try {
            const { servicesToAdd, servicesToRemove } = getChangedServicesToSave(values, services[0], dirtyFields);
            if (servicesToAdd.length) {
                onSaveHandler(servicesToAdd);
            }
            if (servicesToRemove.length) {
                onRemoveHandler(servicesToRemove);
            }
            const updatedTravellersData = getUpdatedTravellersPhones(values, dirtyFields);
            if (updatedTravellersData.length) {
                const { data } = yield addTravellersDataMutation({
                    variables: {
                        variables: {
                            orderId,
                            travellersData: updatedTravellersData
                        }
                    }
                });
                if (data.SetTravellersData) {
                    dispatch(fillOrder(data.SetTravellersData));
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }), [dispatch]);
    return {
        isAddTravellersDataLoading,
        onSubmit
    };
};
