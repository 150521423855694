import { AviaPassengerType, GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { CartService } from '../Cart/types';
import { isPetBaggage } from '../Checkout/utils';
import { getFlatArraySet, getMinPrice, getSegmentGroupsMap, isUnconfirmedService } from '../utils';
/**
 * Return OrderAdditionalServiceGdsServiceServiceType by ServiceType
 * @param {ServiceType} type - Extra service type
 * @returns {OrderAdditionalServiceGdsServiceServiceType}
 */
export function getGdsServiceTypeByServiceType(type) {
    switch (type) {
        case 'seats':
            return OrderAdditionalServiceGdsServiceServiceType.Seat;
        case 'baggage':
        case 'baggage-delivery':
        case 'animal':
            return OrderAdditionalServiceGdsServiceServiceType.Baggage;
        case 'meal':
            return OrderAdditionalServiceGdsServiceServiceType.Meal;
        case 'business-lounge':
        case 'priority-boarding':
        case 'sms':
            return OrderAdditionalServiceGdsServiceServiceType.Vip;
        default:
            return OrderAdditionalServiceGdsServiceServiceType.Misc;
    }
}
/**
 * Return GdsServiceVipSubType by ServiceType
 * @param {ServiceType} type - Extra service type
 * @returns {GdsServiceVipSubType}
 */
export function getGdsSubTypeByServiceType(type) {
    switch (type) {
        case 'baggage-delivery':
            return GdsServiceVipSubType.BaggageDelivery;
        case 'business-lounge':
            return GdsServiceVipSubType.BusinessLounge;
        case 'priority-boarding':
            return GdsServiceVipSubType.PriorityBoarding;
        case 'sms':
            return GdsServiceVipSubType.SMS;
        default:
            return GdsServiceVipSubType.UnknownSubtype;
    }
}
/**
 * Return cart prices key by service type
 * @param {ServiceType} type - Extra service type
 * @returns {string}
 */
export function getCartPriceKeyByType(type) {
    switch (type) {
        case 'seats':
            return 'seats';
        case 'baggage':
            return 'baggage';
        case 'baggage-delivery':
            return 'baggageDelivery';
        case 'animal':
            return 'animals';
        case 'meal':
            return 'meals';
        case 'business-lounge':
            return 'businessLounge';
        case 'priority-boarding':
        case 'capsule-hotel':
            return 'vip';
        case 'insurance':
            return 'insurance';
        case 'transfer':
            return 'aeroexpress';
        case 'upgrade':
        case 'loyalty':
            return null;
        case 'sms':
            return 'sms';
    }
}
/**
 * Return cart service type by service type
 * @param {ServiceType} type - Extra service type
 * @returns {CartService}
 */
export function getCartServiceTypeByType(type) {
    switch (type) {
        case 'seats':
            return CartService.Seats;
        case 'baggage':
        case 'baggage-delivery':
        case 'animal':
            return CartService.Baggage;
        case 'meal':
            return CartService.Meal;
        case 'business-lounge':
            return CartService.BusinessLounge;
        case 'priority-boarding':
        case 'capsule-hotel':
            return CartService.Extra;
        case 'insurance':
            return CartService.Insurances;
        case 'transfer':
            return CartService.Aeroexpress;
        case 'sms':
            return CartService.SMS;
        case 'loyalty':
        case 'upgrade':
            return null;
    }
}
/**
 * Return filter additional services by service type
 * @param {ServiceType} type - Extra service type
 * @param {FlightFragment} flight - Order flight
 * @param {OrderAdditionalServicesFragment} additionalServices - Order additional services
 * @param {string[]} availableProgramsCodes - Insurances available codes
 * @param {boolean} animalsAvailable - Animals service availability
 * @returns {OrderAdditionalServicesFragment}
 */
export function filterGdsServicesByType(type, flight, additionalServices, availableProgramsCodes, animalsAvailable) {
    var _a, _b, _c, _d;
    // Insurance services by type
    if (type === 'insurance') {
        if (!((_b = (_a = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length)) {
            return additionalServices;
        }
        return Object.assign(Object.assign({}, additionalServices), { insurances: Object.assign(Object.assign({}, additionalServices.insurances), { insurancePrograms: additionalServices.insurances.insurancePrograms.filter(insurance => {
                    if (!(availableProgramsCodes === null || availableProgramsCodes === void 0 ? void 0 : availableProgramsCodes.length)) {
                        return true;
                    }
                    return availableProgramsCodes.includes(insurance.code);
                }) }) });
    }
    // Gds services by type
    if (!((_d = (_c = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.length)) {
        return additionalServices;
    }
    if (type === 'baggage-delivery') {
        return Object.assign(Object.assign({}, additionalServices), { gdsServices: Object.assign(Object.assign({}, additionalServices.gdsServices), { services: additionalServices.gdsServices.services
                    .filter(service => service.subType === GdsServiceVipSubType.BaggageDelivery)
                    .reduce((services, service) => {
                    const vipService = service;
                    const segmentsMap = getSegmentGroupsMap(flight.segmentGroups);
                    const allowedSegmentsSet = getFlatArraySet(service.allowedSegments);
                    const newServices = [];
                    allowedSegmentsSet.forEach(segmentId => {
                        var _a;
                        // WEBSKY-2899: сегмент должен быть без пересадки
                        if (((_a = segmentsMap.get(segmentId)) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                            newServices.push(Object.assign(Object.assign({}, vipService), { allowedSegments: [[segmentId]] }));
                        }
                    });
                    return [...services, ...newServices];
                }, []) }) });
    }
    return Object.assign(Object.assign({}, additionalServices), { gdsServices: Object.assign(Object.assign({}, additionalServices.gdsServices), { services: additionalServices.gdsServices.services.filter(service => {
                if (service.type === OrderAdditionalServiceGdsServiceServiceType.Vip ||
                    service.type === OrderAdditionalServiceGdsServiceServiceType.BusinessLounge) {
                    const vipService = service;
                    return vipService.subType === getGdsSubTypeByServiceType(type);
                }
                const sameGdsType = service.type === getGdsServiceTypeByServiceType(type);
                if (type === 'baggage') {
                    const baggageService = service;
                    return sameGdsType && (!animalsAvailable || (animalsAvailable && !isPetBaggage(baggageService)));
                }
                if (type === 'animal') {
                    const baggageService = service;
                    return sameGdsType && isPetBaggage(baggageService);
                }
                return sameGdsType;
            }) }) });
}
/**
 * Return prices by passenger
 * @param {ServiceType} type - Extra service type
 * @param {OrderAdditionalServicesFragment} additionalServices - Order additional services
 * @param {OrderPassenger[]} travellers - Travellers
 * @param {boolean} minPrice - Find minimal prices
 * @returns ${CardExtraPrice[]}
 */
export function getPassengerPrices(type, additionalServices, travellers, minPrice) {
    var _a, _b;
    const pricesMap = new Map();
    if (type === 'insurance' || type === 'transfer') {
        const servicesPrices = getServicesPrices(type, additionalServices);
        if (servicesPrices.length) {
            travellers.forEach(traveller => {
                const type = traveller.type;
                if (type !== AviaPassengerType.INF) {
                    pricesMap.set(type, {
                        type,
                        supplierType: getPassengerSupplierType(traveller),
                        price: minPrice ? getMinPrice(servicesPrices, price => price) : servicesPrices[0]
                    });
                }
            });
        }
    }
    else {
        (_b = (_a = additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.forEach(service => {
            const allowedPassengerIds = new Set(service.allowedPassengers.reduce((acc, ids) => [...acc, ...ids], []));
            allowedPassengerIds.forEach(passengerId => {
                const traveller = travellers.find(traveller => traveller.id === passengerId);
                if (traveller) {
                    const type = traveller.type;
                    const supplierType = getPassengerSupplierType(traveller);
                    const existPrice = pricesMap.get(type);
                    const isLessPrice = minPrice && existPrice && existPrice.price.amount > service.price.amount;
                    if (!existPrice || isLessPrice) {
                        pricesMap.set(type, { type, supplierType, price: service.price });
                    }
                }
            });
        });
    }
    return [...pricesMap.values()];
}
/**
 * Return can modify service value
 * @param {GdsServiceFragment} service - Gds service
 * @param {OrderPassenger} travellers - Travellers
 * @returns {boolean}
 */
export function canBeModifyService(service, travellers) {
    return [...new Set(service.allowedPassengers.reduce((acc, ids) => [...acc, ...ids], []))].some(passengerId => {
        var _a, _b, _c, _d, _e;
        const passengerServices = (_a = travellers.find(traveller => traveller.id === passengerId)) === null || _a === void 0 ? void 0 : _a.services;
        if ((passengerServices === null || passengerServices === void 0 ? void 0 : passengerServices.gdsServices) || (passengerServices === null || passengerServices === void 0 ? void 0 : passengerServices.brandIncludedServices)) {
            const gdsService = (_c = (_b = passengerServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(selectedService => selectedService.serviceId === service.id);
            if (gdsService) {
                return gdsService.count > gdsService.confirmedCount;
            }
            const brandIncludedService = (_e = (_d = passengerServices.brandIncludedServices) === null || _d === void 0 ? void 0 : _d.services) === null || _e === void 0 ? void 0 : _e.find(includedService => includedService.serviceId === service.id);
            if (brandIncludedService) {
                return brandIncludedService.count > brandIncludedService.confirmedCount;
            }
        }
        return service.canBeAdded;
    });
}
/**
 * Return service visible value
 * @param {ServiceType} type - Extra service type
 * @param {FlightFragment} flight - Order flight
 * @param {OrderPassenger} travellers - Order travellers
 * @param {OrderAdditionalServicesFragment} additionalServices - Additional services
 * @param {boolean} canModifyService - Modify services availability
 * @param {CardExtraVisibleOptions} options - Visible options
 * @param {boolean} ffpSupported - Ffp supported
 * @returns {boolean}
 */
export function getServiceVisible(type, flight, travellers, additionalServices, canModifyService, ffpSupported, options) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const hasGdsServices = ((_b = (_a = additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length) > 0;
    switch (type) {
        case 'seats':
            const hasAvailableSeats = (_d = (_c = flight === null || flight === void 0 ? void 0 : flight.seatMap) === null || _c === void 0 ? void 0 : _c.segments) === null || _d === void 0 ? void 0 : _d.some(segment => segment.availableSeatsCount > 0 || !segment.isSeatsAvailableInCheckinOnly);
            const hasTravellerSelectedSeats = travellers === null || travellers === void 0 ? void 0 : travellers.some(traveller => { var _a, _b; return ((_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length) > 0; });
            return hasAvailableSeats || hasTravellerSelectedSeats;
        case 'baggage':
            const hasTravellersIncludedBaggage = travellers === null || travellers === void 0 ? void 0 : travellers.some(traveller => {
                var _a, _b, _c;
                return (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.brandIncludedServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => {
                    var _a;
                    return ((_a = service.service) === null || _a === void 0 ? void 0 : _a.type) === OrderAdditionalServiceGdsServiceServiceType.Baggage &&
                        service.count > 0;
                });
            });
            return canModifyService || (options === null || options === void 0 ? void 0 : options.includedBaggageAvailable) || hasTravellersIncludedBaggage;
        case 'animal':
            return canModifyService && (options === null || options === void 0 ? void 0 : options.animalAvailable);
        case 'insurance':
            return ((_f = (_e = additionalServices.insurances) === null || _e === void 0 ? void 0 : _e.insurancePrograms) === null || _f === void 0 ? void 0 : _f.length) > 0;
        case 'transfer':
            return ((_j = (_h = (_g = additionalServices.transfer) === null || _g === void 0 ? void 0 : _g.aeroexpress) === null || _h === void 0 ? void 0 : _h.trips) === null || _j === void 0 ? void 0 : _j.length) > 0;
        case 'loyalty':
            return ffpSupported;
        default:
            return canModifyService && hasGdsServices;
    }
}
/**
 * Return services prices by service type
 * @param {ServiceType} type - Extra service type
 * @param {OrderAdditionalServicesFragment} additionalServices - Additional services
 * @returns {MoneyType[]}
 */
export function getServicesPrices(type, additionalServices) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (type === 'insurance') {
        return (_c = (_b = (_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.map(program => program.price)) !== null && _c !== void 0 ? _c : [];
    }
    else if (type === 'transfer') {
        return (_g = (_f = (_e = (_d = additionalServices.transfer) === null || _d === void 0 ? void 0 : _d.aeroexpress) === null || _e === void 0 ? void 0 : _e.trips) === null || _f === void 0 ? void 0 : _f.map(trip => trip.price)) !== null && _g !== void 0 ? _g : [];
    }
    return (_k = (_j = (_h = additionalServices.gdsServices) === null || _h === void 0 ? void 0 : _h.services) === null || _j === void 0 ? void 0 : _j.map(service => service.price)) !== null && _k !== void 0 ? _k : [];
}
/**
 * Return unconfirmed services
 * @param {OrderAdditionalServiceGdsServiceServiceType} gdsServiceType - Gds service type
 * @param {PassengerFragment} travellers - Travellers
 * @param {SelectedServicesState} selectedServices - Selected services state
 * @returns {ServicePayload[]}
 */
export function getUnconfirmedServices(gdsServiceType, travellers, selectedServices) {
    var _a, _b, _c, _d, _e;
    const services = [];
    if ((_a = Object.keys(selectedServices !== null && selectedServices !== void 0 ? selectedServices : {})) === null || _a === void 0 ? void 0 : _a.length) {
        for (const passengerId in selectedServices) {
            for (const serviceId in selectedServices[passengerId]) {
                const traveller = travellers.find(traveller => traveller.id === passengerId);
                if (!traveller) {
                    continue;
                }
                const service = (_d = (_c = (_b = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _b === void 0 ? void 0 : _b.gdsServices) === null || _c === void 0 ? void 0 : _c.services) === null || _d === void 0 ? void 0 : _d.find(service => service.serviceId === serviceId);
                if (!service) {
                    continue;
                }
                const isUnconfirmed = (_e = service.products) === null || _e === void 0 ? void 0 : _e.some(product => isUnconfirmedService(product.status));
                if (isUnconfirmed) {
                    services.push({
                        passengerId,
                        serviceId: service.serviceId,
                        segmentId: service.segmentIds[0],
                        service: {
                            id: service.serviceId,
                            type: gdsServiceType
                        }
                    });
                }
            }
        }
    }
    return services;
}
/**
 * Service payload adapter
 * @param {CardExtraGdsService} service - Card extra gds service
 * @param {OrderAdditionalServiceGdsServiceServiceType} serviceGdsType - Gds service type
 * @param {number} count - Services count
 * @return {ServicePayload}
 */
export function extraGdsServiceToServicePayload(service, serviceGdsType, count) {
    return {
        count: count !== null && count !== void 0 ? count : service.count,
        serviceId: service.serviceId,
        passengerId: service.passengerId,
        segmentId: service.segmentIds[0],
        service: { id: service.serviceId, type: serviceGdsType }
    };
}
/**
 * @param {ServiceType} type - Extra service type
 * @return {boolean}
 */
export function isBaggageService(type) {
    return type === 'baggage' || type === 'animal';
}
/**
 * @param {ServiceType} type - Extra service type
 * @return {boolean}
 */
export function isVipService(type) {
    return (type === 'business-lounge' ||
        type === 'priority-boarding' ||
        type === 'baggage-delivery' ||
        type === 'capsule-hotel' ||
        type === 'sms');
}
/**
 * @param {ServiceType} type - Extra service type
 * @param {OrderAdditionalServicesFragment} additionalServices - Additional services
 */
export function isSingleService(type, additionalServices) {
    var _a, _b, _c, _d, _e;
    if (type === 'insurance') {
        return ((_b = (_a = additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length) === 1;
    }
    else if (type === 'transfer') {
        return ((_e = (_d = (_c = additionalServices.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.trips) === null || _e === void 0 ? void 0 : _e.length) === 1;
    }
    return false;
}
/**
 * Checkout passenger guard
 * @param {OrderPassenger} passenger - Checkout or checkin passenger
 * @return {boolean}
 */
export function isCheckoutPassenger(passenger) {
    return (passenger === null || passenger === void 0 ? void 0 : passenger.supplierTravellerType) !== undefined;
}
export function getPassengerSupplierType(passenger) {
    return isCheckoutPassenger(passenger) ? passenger.supplierTravellerType : undefined;
}
