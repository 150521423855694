import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import ImageWithFallback from '../../../ImageWithFallback';
import Button from '../../../BaseComponents/Button';
import Money from '../../../Money';
import { useCardExtraIconSource } from '../../hooks';
const CardExtraContent = ({ type, insuranceCodes, content, additionalControls, title, description, minPrice, totalPrice, isSelected, isLoading, onChangeService, onToggleService, onViewLoyaltyStatus }) => {
    const { t } = useTranslation('CardExtra');
    const { CardExtraContent: theme } = useTheme('CardExtra');
    const iconSource = useCardExtraIconSource(type);
    const buttonClasses = cn(theme.button, {
        [theme.button_add]: !isSelected,
        [theme.button_change]: isSelected
    });
    return (React.createElement("div", { className: cn(theme.root, {
            [theme.root_seats]: type === 'seats',
            [theme.root_baggage]: type === 'baggage',
            [theme.root_baggageDelivery]: type === 'baggage-delivery',
            [theme.root_animal]: type === 'animal',
            [theme.root_meal]: type === 'meal',
            [theme.root_businessLounge]: type === 'business-lounge',
            [theme.root_priorityBoarding]: type === 'priority-boarding',
            [theme.root_insurance]: type === 'insurance',
            [theme.root_transfer]: type === 'transfer',
            [theme.root_capsuleHotel]: type === 'capsule-hotel',
            [theme.root_loyalty]: type === 'loyalty',
            [theme.root_sms]: type === 'sms'
        }), "data-service-type": type, "data-insurance-code": insuranceCodes === null || insuranceCodes === void 0 ? void 0 : insuranceCodes[0] },
        React.createElement("div", { className: theme.header },
            React.createElement("div", { className: theme.header__title }, title !== null && title !== void 0 ? title : t(`ServiceTitle.${type}`)),
            React.createElement("div", { className: theme.header__icon },
                React.createElement(ImageWithFallback, Object.assign({}, iconSource)))),
        React.createElement("div", { className: theme.content },
            content,
            !isSelected && (React.createElement("div", { className: theme.description, dangerouslySetInnerHTML: { __html: description !== null && description !== void 0 ? description : t(`ServiceDescription.${type}`) } }))),
        additionalControls && React.createElement("div", { className: theme.controls }, additionalControls),
        React.createElement("div", { className: theme.footer },
            type !== 'loyalty' && (React.createElement("span", { className: theme.footer__price }, isSelected
                ? totalPrice && (React.createElement("span", null,
                    t('total'),
                    ":",
                    React.createElement(Money, { money: totalPrice })))
                : minPrice && (React.createElement("span", null,
                    t('from'),
                    React.createElement(Money, { money: minPrice }))))),
            type === 'loyalty' ? (React.createElement(Button, { className: buttonClasses, variant: isSelected ? 'text' : 'optionAction', onClick: isSelected ? onViewLoyaltyStatus : onChangeService, isLoading: isLoading }, t(isSelected ? 'View status' : 'Register'))) : (React.createElement(Button, { className: buttonClasses, variant: isSelected ? 'optionEdit' : 'optionAction', onClick: onToggleService !== null && onToggleService !== void 0 ? onToggleService : onChangeService, isLoading: isLoading }, t(isSelected ? (onToggleService ? 'Remove' : 'Edit') : 'Add'))))));
};
export default CardExtraContent;
