import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIsConfirmed } from '../../../Checkout/store/order/selectors';
import { useConfig } from '../../../context';
import { groupSeats, groupServices } from '../../../Cart/utils';
import { getCartServiceTypeByType, getGdsSubTypeByServiceType, isBaggageService as getIsBaggageService, isVipService as getIsVipService } from '../../utils';
import { isPetBaggage } from '../../../Checkout/utils';
import { getTripFullLabel, getServiceOptionItem } from './utils';
import { getTotalPrice, getTravellersMap } from '../../../utils';
import { Meal, PetInHold, SeatSelection } from '../../../Icons';
import { useCardExtraCtx } from '../CardExtra.context';
export function useCardExtraSummary() {
    const { t } = useTranslation('CardExtra');
    const isConfirmed = useSelector(getIsConfirmed);
    const { global: { servicesAvailable } } = useConfig();
    const { type, flight, travellers, additionalServicesByType, gdsServiceType, onRemoveServices } = useCardExtraCtx();
    // Data
    const travellersById = useMemo(() => getTravellersMap(travellers), [travellers]);
    const cartServiceType = useMemo(() => getCartServiceTypeByType(type), [type]);
    const isBaggageService = useMemo(() => getIsBaggageService(type), [type]);
    const isVipService = useMemo(() => getIsVipService(type), [type]);
    const filterServiceByType = useCallback((service) => {
        if (isBaggageService) {
            const baggageService = service;
            if (servicesAvailable.animal) {
                const petBaggage = isPetBaggage(baggageService);
                return type === 'animal' ? petBaggage : !petBaggage;
            }
        }
        return true;
    }, [type, isBaggageService, servicesAvailable]);
    const customFilterService = useCallback((service) => {
        const vipService = service;
        return vipService.subType === getGdsSubTypeByServiceType(type);
    }, [type]);
    const items = useMemo(() => {
        var _a, _b, _c, _d;
        const groups = [];
        const allSegmentsGroups = [];
        if (type === 'insurance') {
            const insurancePrograms = (_a = additionalServicesByType.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms;
            const selectedInsurance = (_b = additionalServicesByType.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances;
            if ((insurancePrograms === null || insurancePrograms === void 0 ? void 0 : insurancePrograms.length) && (selectedInsurance === null || selectedInsurance === void 0 ? void 0 : selectedInsurance.length)) {
                const items = [];
                const insuranceWithCountMap = new Map();
                const insuranceProgramCodes = new Set(insurancePrograms.map(program => program.code));
                selectedInsurance.forEach(insurance => {
                    var _a, _b;
                    const code = insurance.insuranceProgram.code;
                    if (insuranceProgramCodes.has(code)) {
                        insuranceWithCountMap.set(code, Object.assign(Object.assign({}, insurance.insuranceProgram), { count: ((_b = (_a = insuranceWithCountMap.get(code)) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0) + 1 }));
                    }
                });
                insuranceWithCountMap.forEach(insurance => {
                    items.push({
                        id: insurance.code,
                        name: `${insurance.count}x ${insurance.name}`,
                        price: insurance.totalPrice,
                        included: isConfirmed,
                        onDelete: () => onRemoveServices([{ code: insurance.code }])
                    });
                });
                if (items.length) {
                    groups.push({ items });
                }
            }
        }
        else if (type === 'transfer') {
            const selectedTransfer = (_d = (_c = additionalServicesByType.transfer) === null || _c === void 0 ? void 0 : _c.aeroexpress) === null || _d === void 0 ? void 0 : _d.selectedTrips;
            if (selectedTransfer === null || selectedTransfer === void 0 ? void 0 : selectedTransfer.length) {
                selectedTransfer.forEach(selectedTransfer => {
                    groups.push({
                        header: t('All passengers'),
                        items: [
                            {
                                id: selectedTransfer.id,
                                name: getTripFullLabel(selectedTransfer),
                                price: selectedTransfer.price,
                                onDelete: () => onRemoveServices([{ id: selectedTransfer.id }])
                            }
                        ]
                    });
                });
            }
        }
        else if (type === 'seats') {
            const travellersWithSeat = travellers.filter(traveller => { var _a, _b; return (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length; });
            if (travellersWithSeat.length) {
                travellersWithSeat.forEach(traveller => {
                    var _a;
                    const items = [];
                    const seats = groupSeats(traveller);
                    const seatValues = Object.values((_a = seats[traveller.id]) !== null && _a !== void 0 ? _a : {});
                    if (seatValues.length) {
                        seatValues.forEach(seat => {
                            if (seat.additional) {
                                const seatServices = seat.additional;
                                seatServices.forEach(seatService => {
                                    var _a;
                                    const segment = flight.segments.find(segment => { var _a; return segment.segment.id === ((_a = seatService.segment) === null || _a === void 0 ? void 0 : _a.id); });
                                    const direction = `${segment.segment.departure.airport.iata} – ${segment.segment.arrival.airport.iata}`;
                                    const price = getTotalPrice(seatService.seat.seatServices, service => service.price);
                                    items.push({
                                        id: seatService.seat.number,
                                        name: `${direction}, ${seatService.seat.number}`,
                                        price,
                                        included: seatService.isConfirmed,
                                        status: (_a = seatService.product) === null || _a === void 0 ? void 0 : _a.status,
                                        onDelete: () => onRemoveServices([
                                            {
                                                count: 0,
                                                serviceId: seatService.seat.number,
                                                passengerId: traveller.id,
                                                segmentIds: [seatService.segment.id]
                                            }
                                        ])
                                    });
                                });
                            }
                        });
                    }
                    if (items.length) {
                        groups.push({
                            headerIcon: SeatSelection,
                            headerType: 'travellerName',
                            traveller: travellersById.get(traveller.id),
                            items
                        });
                    }
                });
            }
        }
        else {
            travellers.forEach(traveller => {
                var _a;
                const optionItems = [];
                const allSegmentsOptionItems = [];
                const groupedServices = groupServices(additionalServicesByType, traveller, traveller.id, gdsServiceType, flight.segments, filterServiceByType, isVipService ? customFilterService : undefined);
                const servicesKeys = Object.keys((_a = groupedServices[traveller.id]) !== null && _a !== void 0 ? _a : {});
                if (servicesKeys.length) {
                    servicesKeys.forEach(key => {
                        var _a;
                        const groupedService = (_a = groupedServices[traveller.id]) === null || _a === void 0 ? void 0 : _a[key];
                        const isAllSegments = key === 'allSegments';
                        // Included services (only for baggage)
                        if ((groupedService === null || groupedService === void 0 ? void 0 : groupedService.included) && isBaggageService && type !== 'animal') {
                            groupedService.included.forEach(includedService => {
                                const optionItem = getServiceOptionItem(key, type, includedService, flight, isAllSegments, true);
                                if (isAllSegments) {
                                    allSegmentsOptionItems.push(optionItem);
                                }
                                else {
                                    optionItems.push(optionItem);
                                }
                            });
                        }
                        // Additional services
                        if (groupedService === null || groupedService === void 0 ? void 0 : groupedService.additional) {
                            groupedService.additional.forEach(additionalService => {
                                const segmentIds = isAllSegments
                                    ? flight.segments.map(segment => segment.segment.id)
                                    : [key];
                                const optionItem = getServiceOptionItem(key, type, additionalService, flight, isAllSegments, false, travellersById.get(traveller.id));
                                const serviceOptionItem = Object.assign(Object.assign({}, optionItem), { onDelete: () => onRemoveServices([
                                        {
                                            count: 0,
                                            serviceId: additionalService.serviceId,
                                            passengerId: traveller.id,
                                            segmentIds
                                        }
                                    ]) });
                                if (isAllSegments) {
                                    allSegmentsOptionItems.push(serviceOptionItem);
                                }
                                else {
                                    optionItems.push(serviceOptionItem);
                                }
                            });
                        }
                    });
                }
                if (optionItems.length) {
                    const itemsBySegmentMap = new Map();
                    optionItems.forEach(item => {
                        var _a;
                        const key = item.segment;
                        itemsBySegmentMap.set(key, [...((_a = itemsBySegmentMap.get(key)) !== null && _a !== void 0 ? _a : []), item]);
                    });
                    [...itemsBySegmentMap.entries()].map(([key, services]) => {
                        var _a;
                        groups.push({
                            headerType: type === 'sms' ? 'serviceName' : 'travellerName',
                            headerIcon: type === 'meal' ? Meal : type === 'animal' ? PetInHold : null,
                            traveller: travellersById.get(traveller.id),
                            direction: (_a = flight.segments.find(segment => segment.segment.id === key)) === null || _a === void 0 ? void 0 : _a.segment,
                            items: services
                        });
                    });
                }
                if (allSegmentsOptionItems.length) {
                    allSegmentsGroups.push({
                        headerIcon: type === 'meal' ? Meal : type === 'animal' ? PetInHold : null,
                        headerType: 'travellerName',
                        traveller: travellersById.get(traveller.id),
                        items: allSegmentsOptionItems,
                        allSegments: true
                    });
                }
            });
        }
        return { groups, allSegmentsGroups };
    }, [
        type,
        gdsServiceType,
        flight,
        additionalServicesByType,
        onRemoveServices,
        travellersById,
        isBaggageService,
        isVipService,
        isConfirmed,
        filterServiceByType,
        customFilterService
    ]);
    return {
        // data
        items,
        travellersById,
        cartServiceType,
        isBaggageService,
        isVipService
    };
}
