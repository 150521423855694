import DesktopFlightRouteModal from './components/DesktopFlightRouteModal/DesktopFlightRouteModal';
import { renderCheckoutLinksLabel } from './components/InfoLinksLabel/InfoLinksLabel';
import SeatMapService from './components/SeatMap/SeatMap';
import SpecialEqupmentBaggageCard from './components/Baggage/SpecialEqupment/BaggageCard';
import { overridedIcons, renderHeaderEquipmentIcon } from './components/Icons/overridedIcons';
import Passengers from './components/SeatMap/Passengers/Passengers';
import OrderInsuranceBlockSlotUpsale from '@websky/core/src/Checkout/components/Checkout/OrderInsuranceBlockSlot/OrderInsuranceBlockSlot.upsale';
import CheckoutServicesV2 from '@websky/core/src/Checkout/components/Checkout/ServicesV2/ServicesV2';
export const renders = Object.assign(Object.assign({ DesktopFlightRouteModal,
    renderCheckoutLinksLabel, SeatMapService: SeatMapService, Baggage: {
        SpecialEqupment: {
            BaggageCard: SpecialEqupmentBaggageCard
        }
    } }, overridedIcons), { renderHeaderEquipmentIcon, SeatMapPassengers: Passengers, CheckoutInsurance: OrderInsuranceBlockSlotUpsale, CheckoutServices: CheckoutServicesV2 });
