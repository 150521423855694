import * as React from 'react';
import { initI18n } from '../../utils';
import { useCardExtra } from '../hooks';
import { CardExtraProvider } from './CardExtra.context';
import CardExtraSummary from './CardExtraSummary/CardExtraSummary';
import CardExtraContent from './CardExtraContent/CardExtraContent';
import CardExtraModal from './CardExtraModal/CardExtraModal';
initI18n('CardExtra');
const CardExtra = props => {
    const { type, insuranceCodes } = props;
    const model = useCardExtra(props);
    if (!model.showService) {
        return null;
    }
    return (React.createElement(CardExtraProvider, { value: model },
        React.createElement(CardExtraSummary, null, summaryProps => (React.createElement(CardExtraContent, { type: type, insuranceCodes: insuranceCodes, title: summaryProps.title, description: summaryProps.description, content: summaryProps.content, additionalControls: summaryProps.additionalControls, minPrice: model.cardExtraPrices.minPrice, totalPrice: model.cardExtraPrices.totalPrice, isSelected: summaryProps.isSelected, isLoading: model.isLoading, onChangeService: model.cardExtraDisclosure.onOpenModal, onToggleService: model.isSingleService ? model.onToggleService : null, onViewLoyaltyStatus: model.onViewLoyaltyStatus }))),
        React.createElement(CardExtraModal, { openServiceType: model.cardExtraDisclosure.openServiceType, passengerPrices: model.cardExtraPrices.passengerPrices, isOpenModal: model.cardExtraDisclosure.isOpenModal, isDifferentPrices: model.cardExtraPrices.isDifferentPrices, onClose: model.cardExtraDisclosure.onCloseModal })));
};
export default CardExtra;
